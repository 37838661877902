import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Radio from "components/Radio/Radio";
import AveRange from "components/AveRange/AveRange";
import PriceBGImage from "assets/images/estrellas.png";
import AVELogo from "assets/images/AVE_logo_white.svg";
import "./PricingSection.scss";

const PriceTypes = [
  {
    id: 0,
    label: "Crédito Ave",
    name: "Simple",
    selected: false,
    info: "Producto de financiamiento que otorga la liquidez necesaria para que los negocios alcancen sus metas a mediano-largo plazo.",
  },
  {
    id: 2,
    label: "Factoraje",
    name: "Factoraje",
    selected: false,
    info: "Fuente de financiamento a corto plazo que proporciona liquidez inmediata a los negocios a cambio de sus cuentas por cobrar.",
  },
  {
    id: 1,
    label: "Arrendamiento",
    name: "Arrendamiento",
    selected: true,
    info: " Utiliza activos productivos sin descapitalizarte y deduce hasta el 100% de las rentas.",
  },

];

const termOptions = [
  { id: 0, label: "30 días", pros: 1.8, labelPagos: "1 pago mensual", plazo: 30 },
  { id: 1, label: "60 días", pros: 1.86, labelPagos: "2 pagos mensuales", plazo: 60 },
  { id: 2, label: "90 días", pros: 1.93, labelPagos: "3 pagos mensuales", plazo: 90 },
  { id: 3, label: "120 días", pros: 2.0, labelPagos: "4 pagos mensuales", plazo: 120 },
];

const termOptions_1 = [
  { id: 0, label: "24 meses", pros: 1.67, labelPagos: "24 pagos mensuales", plazo: 24 },
  { id: 1, label: "36 meses", pros: 1.79, labelPagos: "36 pagos mensuales", plazo: 36 },
  { id: 2, label: "48 meses", pros: 1.88, labelPagos: "48 pagos mensuales", plazo: 48 },
  { id: 3, label: "60 meses", pros: 1.97, labelPagos: "60 pagos mensuales", plazo: 60 },
];

const termOptions_2 = [
  { id: 0, label: "6 meses", pros: 21.5, labelPagos: "6 pagos mensuales", plazo: 6 },
  { id: 1, label: "12 meses", pros: 22.26, labelPagos: "12 pagos mensuales", plazo: 12 },
  { id: 2, label: "18 meses", pros: 23.05, labelPagos: "18 pagos mensuales", plazo: 18 },
  { id: 3, label: "24 meses", pros: 23.86, labelPagos: "24 pagos mensuales", plazo: 24 },
];

const PricingSection = () => {
  const [selectedType, setSelectedType] = useState(PriceTypes[0]);
  const [price, setPrice] = useState([1000000]);
  const [residual, setResidual] = useState([10]);

  const [tasa, setTasa] = useState(0);
  const [selectedTerm, setSelectedTerm] = useState(termOptions[0].id);
  const navigate = useNavigate();

  const onChangeType = (type) => {
    setSelectedType(type);
  };

  const onSelectTerm = (termId) => {
    let pros = 0;
    if (selectedType.label === "Factoraje") {
      pros = termOptions[termId].pros;
    } else if (selectedType.label === "Arrendamiento") {
      pros = termOptions_1[termId].pros;
    } else {
      pros = termOptions_2[termId].pros;
    }
    setSelectedTerm(termId);
    setTasa(pros);
  };

  const plazo = {
    Factoraje: termOptions,
    Arrendamiento: termOptions_1,
    "Crédito Ave": termOptions_2,
  };

  useEffect(() => {
    if (selectedType.label === "Factoraje") {
      setTasa(termOptions[0].pros);
    } else if (selectedType.label === "Arrendamiento") {
      setTasa(termOptions_1[0].pros);
    } else {
      setTasa(termOptions_2[0].pros);
    }
    setSelectedTerm(0);
  }, [selectedType]);

  const calculateMonthlyPayment = (price, selectedTerm, selectedType, annualRate) => {
    let payment = 0.0;

    if (selectedType.label === "Factoraje") {
      let rate = (annualRate * 12) / 100;
      payment = ((rate / 360) * 30) * price
    } else {
      payment = ((((annualRate / 100) / 360) * 30) * price) + price / selectedTerm;
    }
    let formattedPayment = payment.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return '$' + formattedPayment;
  };

  const calculateResidual = (residual, originalAmount,) => {
    let residualPayment = 0.0;
    residualPayment = originalAmount * residual / 100;
    return residualPayment.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  function calculateValueArrendamiento(activeValue, monthlyRate, timePeriod, residualValue) {
    monthlyRate = monthlyRate / 100;
    let residualPayment = (activeValue * residualValue) / 100;
    let payment =
      (activeValue * monthlyRate * Math.pow(1 + monthlyRate, timePeriod) - residualPayment * monthlyRate) /
      (Math.pow(1 + monthlyRate, timePeriod) - 1);
    let formattedPayment = payment.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return '$' + formattedPayment;
  }

  return (
    <div className="relative flex justify-center">
      <div className="absolute w-full h-auto inset-0 z-10">
        <img
          src={PriceBGImage}
          alt=""
          className="w-full h-full object-cover object-top"
        />
      </div>
      <div className="px-[40px] md:px-[40px] xl:px-[0px] w-full xl:w-[1050px] z-[11] mt-[50px] sm:mt-[70px] mb-[150px]">
        <div className="flex gap-[10px] sm:gap-[30px]">
          <div className="financialText">Financiamento</div>
          <div className="xl:mt-[-20px]">
            <img
              src={AVELogo}
              alt=""
              className="w-[85px] h-[32px] xl:w-[160px] xl:h-[60px] object-cover"
            />
          </div>
        </div>
        <div className="quoteText mt-[10px] sm:mt-[20px]">
          Cotiza y obtén respuesta en menos de 72 horas
        </div>
        <div className="startTopbar mt-[30px]">Simula tu financiamiento</div>
        <div className="startMain w-full xl:flex">
          <div className="w-full xl:w-[67%] p-[15px] sm:p-[30px]">
            <div className="howMuch">
              ¿Qué tipo de financiamiento necesitas?
            </div>
            <div className="w-[full] flex justify-center sm:justify-start sm:w-full mt-[25px]">
              <Radio
                data={PriceTypes}
                selected={selectedType}
                onChange={(item) => onChangeType(item)}
                gap={1}
              />
            </div>
            <div className="howMuch mt-[25px]">
              {selectedType.label === "Arrendamiento"
                ? "¿Cuál es el valor del activo a arrendar?"
                : selectedType.label === "Factoraje" ? "Monto de la(s) factura(s) que quieres adelantar" : "¿Cuánto dinero necesita tu negocio?"}
            </div>

            <div className="mt-[30px]">
              <div className="w-full flex xl:hidden justify-center mt-[20px] sm:mt-[0px]">
                <div className="priceArea">${price.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
              </div>
              <div className="w-full pl-[10px] pr-[10px]">
                <AveRange
                  step={25000}
                  min={100000.00}
                  max={selectedType.label === "Arrendamiento" ? 10000000 : 5000000}
                  value={price}
                  onChange={(value) => {
                    setPrice(value);
                  }}
                />
              </div>
              <div className="w-full flex justify-between">
                <div>$100,000</div>
                <div>{selectedType.label === "Arrendamiento" ? "$10,000,000" : "$5,000,000"}</div>
              </div>
              <div className="w-full hidden xl:flex justify-center">
                <div className="priceArea">${price.toLocaleString()}</div>
              </div>
            </div>

            {selectedType.label === "Arrendamiento" && (
              <>
                <div className="howMuch mt-[25px]">Valor residual</div>
                <div className="mt-[30px]">
                  <div className="w-full flex xl:hidden justify-center mt-[20px] sm:mt-[0px]">
                    <div className="priceArea">
                      <div className="priceArea">{residual}%</div>
                    </div>
                  </div>
                  <div className="w-full pl-[10px] pr-[10px]">
                    <AveRange
                      step={5}
                      min={5.00}
                      max={30.00}
                      value={residual}
                      onChange={(value) => {
                        setResidual(value);
                      }}
                    />
                  </div>
                  <div className="w-full flex justify-between">
                    <div>5%</div>
                    <div>30%</div>
                  </div>
                  <div className="w-full hidden xl:flex justify-center">
                    <div className="priceArea">{residual}%</div>
                  </div>
                </div>
              </>
            )}

            <div className="howMuch mt-[30px]">
              <div>¿A qué plazo?</div>
              <div className="w-full flex mt-[25px] gap-[5px] sm:gap-[20px]">
                {plazo[selectedType.label].map((term) => (
                  <div
                    key={term.id}
                    className="containerBtn"
                    onClick={() => onSelectTerm(term.id)}
                  >
                    <div
                      className={`${selectedTerm === term.id ? "" : "buttonPrice"} m-auto`}
                    >
                      {term.label}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="w-full xl:w-[33%] h-auto priceRightBG">
            <div className="pt-[40px] xl:pt-[50px]">
              <div className="priceRightTitle">
                {selectedType.label === "Arrendamiento"
                  ? "Monto Arrendamiento"
                  : "Te prestamos hasta"}
              </div>
              <div className="priceRightValue">$  {selectedType.label === "Factoraje"
                ? (price * 0.8).toLocaleString()
                : price.toLocaleString()
              }
              </div>
            </div>
            {selectedType.label != "Arrendamiento" && (
              <>
                <div className="mt-[30px]">
                  <div className="priceRightTitle">
                    {selectedType.label === "Factoraje"
                      ? "A una tasa mensual desde"
                      : "A una tasa anual desde"}
                  </div>
                  <div className="priceRightValue">{tasa}%</div>
                </div>
              </>
            )}
            <div className="mt-[30px]">
              <p className="priceRightTitle">
                {selectedType.label === "Arrendamiento" ? "" : "Y"} {plazo[selectedType.label][selectedTerm].labelPagos} de
              </p>
              <p className="priceRightValue">
                {selectedType.label === "Arrendamiento"
                  ? calculateValueArrendamiento(price, tasa, plazo[selectedType.label][selectedTerm].plazo, residual)
                  : calculateMonthlyPayment(price, plazo[selectedType.label][selectedTerm].plazo, selectedType, tasa)}
              </p>
            </div>
            {selectedType.label === "Arrendamiento" && (
              <>
                <div className="mt-[30px]">
                  <div className="priceRightTitle">
                    Y un valor residual de
                  </div>
                  <div className="priceRightValue">
                    ${calculateResidual(residual, price)}
                  </div>
                </div>
              </>
            )}
            <div className="w-full flex justify-center mt-[40px] pb-[40px]">
              <div
                onClick={() => navigate("/signin")}
                className="creditBtn cursor-pointer"
              >
                Solicita tu crédito
              </div>
            </div>
          </div>
        </div>
        <div className="priceDesc w-full text-white mt-[10px] text-left">
          El simulador tiene fines demostrativos. El monto y el precio del
          crédito también estarán sujetos a otros factores tales como la
          situación financiera del negocio y su historial créditício. Los pagos
          mensuales para Crédito Ave consideran intereses y amortización de capital lineal.
        </div>
      </div>
    </div>
  );
};

export default PricingSection;
