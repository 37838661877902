import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "assets/images/AVE_logo_white.svg";
import condusef from "assets/images/CONDUSEF.png";
import buro from "assets/images/BURO.png";
import linkedin from "assets/images/linkedin.svg";
import twitter from "assets/images/twitter-x.svg";
import facebook from "assets/images/facebook.svg";
import instagram from "assets/images/instagram.svg";
import "./FooterSection.scss";

const FooterSection = () => {
  const navigate = useNavigate();
  const [pathName, setPathName] = useState(window.location.pathname);

  useEffect(() => {
    const handleLocationChange = () => {
      setPathName(window.location.pathname);
    };

    // Add event listener for popstate (history changes)
    window.addEventListener("popstate", handleLocationChange);

    // Add event listener for pushState and replaceState (programmatic navigation)
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = function (...args) {
      originalPushState.apply(window.history, args);
      handleLocationChange();
    };

    window.history.replaceState = function (...args) {
      originalReplaceState.apply(window.history, args);
      handleLocationChange();
    };

    // Cleanup function
    return () => {
      window.removeEventListener("popstate", handleLocationChange);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, []);

  return (
    <>
      <footer
        className={`footer ${pathName === "/register" || pathName === "/signin" ? "hidden" : ""}`}
      >
        <div className="maskBG">
          <div className="w-full flex justify-center px-[45px] 2xl:px-[0px] items-center">
            <div className="sm:flex flex-col w-full xl:w-[1388px] xl:mb-[-30px]">
              <div className="py-[20px] xl:py-[0px] sm:py-[20px] mt-[45px]">
                <img src={logo} alt="AVE" width="109" className="" draggable="false"
                />
              </div>
              <div className="flex xl:flex-row flex-col w-full">
                <div className="w-full xl:w-[50%] mt-[30px] xl:mt-[30px]">
                  <div className="flex flex-col justify-between">
                    <div className="footerTextTitle">
                      Alica 49, Piso 2, Molino del Rey, Miguel Hidalgo, 11040,
                      CDMX, México.
                    </div>
                    <div className="footerTextBody pt-[40px] pb-[25px] w-[100%] md:w-[100%] sm:w-[100%]">
                      Ave Márgenes de Eficiencia, S.A. de C.V., SOFOM E.N.R.,
                      para su constitución y operación con tal carácter, no
                      requiere autorización de la Secretaría de Hacienda y
                      Crédito Público (SHCP). Sin embargo, se encuentra sujeta a
                      la supervisión de la Comisión Nacional Bancaria y de
                      Valores (CNBV), únicamente para efectos de lo dispuesto
                      por el artículo 56 de la Ley General de Organizaciones y
                      Actividades Auxiliares del Crédito.
                    </div>
                    <div className="flex flex-row justify-around w-[100%] xl:w-1/2 md:w-[50%] sm:w-[100%]">
                      <a href="https://www.condusef.gob.mx/" target="_blank" rel="noopener noreferrer">
                        <img src={condusef} alt="Condusef" width="100" />
                      </a>
                      <a href="https://www.buro.gob.mx/" target="_blank" rel="noopener noreferrer">
                        <img src={buro} alt="Buró de Crédito" width="80" />
                      </a>
                    </div>
                    <div className="footerTextBody pt-[20px] pb-[10px] w-[100%] md:w-[100%] sm:w-[100%] ">
                      El Buró de Entidades Financieras contiene información de
                      forma de Ave Márgenes de Eficiencia, S.A. de C.V., SOFOM
                      E.N.R. sobre las caracteristicas de nuestros productos y
                      nuestro desempeño frente a los usuarios en la prestación
                      de servicios. Te invitamos a consultarlo en la página
                      https://www.buro.gob.mx/o en nuestra página de internet.
                    </div>
                    <div className="footerTextBody w-[100%] md:w-[100%] sm:w-[100%]">
                      Costo Anual Total (CAT) de financiamiento expresado en
                      términos porcentuales anuales sin IVA que, para fines
                      informativos y de comparación, incorpora la totalidad de
                      los costos y gastos inherentes a los créditos 27.87%.
                      Fecha de cálculo 07/09/2024, vigente hasta el 31/12/2024.
                    </div>
                  </div>
                </div>
                <div className="px-[0px] xl:px-[0px] w-full xl:w-[50%] md:px-[0] overflow-x-auto sm:px-[0]">
                  <div className="mt-20 xl:mt-0 xl:ml-60">
                    <div className="text-md text-left">
                      ¿Sigues teniendo alguna duda? <br></br> <strong>Escríbenos</strong>
                    </div>
                    <div
                      className="text-md text-left py-2 xl:py-7 md:py-5 sm:py-5 underline font-medium cursor-pointer"
                      onClick={() => {
                        window.location.href = "mailto:contacto@avefin.com";
                      }}
                    >
                      contacto@avefin.com
                    </div>
                    <div className="flex flex-row gap-5 py-2 xl:py-7 md:py-5 sm:py-5">
                      <img
                        src={linkedin}
                        alt=""
                        width="32"
                        className="cursor-pointer"
                        onClick={() =>
                          window.open(
                            "https://www.linkedin.com/company/avefin/"
                          )
                        }
                      />
                      <img
                        src={twitter}
                        alt=""
                        width="32"
                        className="cursor-pointer"
                        onClick={() => {
                          window.open("https://www.twitter.com/AveFinancial");
                        }}
                      />
                      <img
                        src={facebook}
                        alt=""
                        width="32"
                        className="cursor-pointer"
                        onClick={() => {
                          window.open("https://www.facebook.com/avefin");
                        }}
                      />
                      <img
                        src={instagram}
                        alt=""
                        width="32"
                        className="cursor-pointer"
                        onClick={() => {
                          window.open("https://www.instagram.com/avefin.mx/");
                        }}
                      />
                    </div>
                    <div
                      onClick={() => navigate("/privacy")}
                      className="text-md text-left py-2 xl:py-7 md:py-5 sm:py-5 underline font-medium cursor-pointer"
                    >
                      Aviso de Privacidad
                    </div>
                    <div
                      onClick={() => navigate("/terms")}
                      className="text-md text-left py-1 underline font-medium cursor-pointer"
                    >
                      Términos y Condiciones
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full flex m-auto justify-center xl:w-[50%] xl:ml-20 xl:mt-[30px] xl:justify-start xl:mb-[0px] mt-[50px] md:-mt-[100px] md:justify-end md:flex sm:mt-[50px] ">
                <div
                  className="registerButton_1 xl:ml-[40px]"
                  onClick={() => navigate("/register")}
                >
                  Registra a tu empresa
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterSection;
